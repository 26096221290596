* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

html {
  min-height: 100vh;
  overflow-x: hidden;
  position: relative;
}

body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translate3d(0, 0, 0);
}

.navbar {
  background-color: #fff;
  padding: 20px;
}

.nav-links {
  margin: 0 7px;
}

.home-body {
  padding: 0;
  margin: 0;
  height: fit-content;
  min-height: calc(100vh - 80px);
  background-color: #131111;
  color: #fafafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-body {
  padding: 0;
  margin: 0;
  height: fit-content;
  min-height: calc(100vh - 80px);
  background-color: #131111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-body {
  padding: 0;
  margin: 0;
  height: fit-content;
  min-height: calc(100vh - 80px);
  background-color: #131111;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content {
  padding: 0;
  margin: 0 auto;
  height: 100%;
  min-height: 70vh;
  max-width: 1000px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.home-content-last {
  padding: 0;
  margin: 0 auto 70px;
  height: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-to-top-btn {
  padding: 10px 25px;
  background-color: #4083ae !important;
  margin-bottom: 60px;
  box-shadow: 2px 2px #ec4c4c;
}

.home-container {
  height: 100%;
  background-color: #131111;
}

.headshot-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.headshot {
  border: 5px solid #fafafa;
  min-width: 5rem;
  max-width: 25rem;
  border: 4px solid #141414;
  border-radius: 3%;
  box-shadow: 2px 2px #4083ae;
}

#greeting {
  min-height: 300px;
}

#greeting h1 {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  line-height: 1.5em;
}

#greeting .name {
  font-size: 3.5em;
  font-weight: bold;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 50px;
  text-shadow: 2px 2px #4083ae;
}

.web-dev {
  color: #ec4c4c;
  word-wrap: break-word;
}

.typed-content {
  word-wrap: break-word;
  min-width: 500px;
}

.active {
  color: #ec4c4c !important;
}

.github-link {
  color: #4083ae;
  text-decoration: none;
}

.contact-card {
  margin: 0 auto;
  padding: 30px 30px;
  height: 100%;
  width: 95%;
  max-width: 800px;
  border: 3px solid #000000;
  border-radius: 10px;
  box-shadow: 5px 5px #4083ae;
}

.visable {
  display: block;
}

.sent-message {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}

.invisible {
  display: none;
}

#submit-btn {
  margin-top: 30px;
  width: 100%;
  background-color: #4083ae !important;
}

.form-label {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
  text-align: center;
}

.reset-btn {
  margin: 50px auto;
  width: 30%;
  min-width: 200px;
  display: block;
  background-color: #4083ae !important;
}

.hello-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  max-width: 800px;
  height: fit-content;
  color: #fafafa;
}

.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  max-width: 1000px;
  height: fit-content;
  color: #fafafa;
}

.hello-container h1 {
  font-size: 2.5em;
  font-weight: bold;
  text-align: center;
  line-height: 1.5em;
  margin-bottom: 50px;
  text-shadow: 2px 2px #4083ae;
}

.hello-container p {
  font-size: 1.3em;
  line-height: 1.5em;
}

.hand-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  height: fit-content;
  color: #fafafa;
  margin-top: 150px;
}

.hand-container h1 {
  font-size: 6em;
  font-weight: bold;
  text-align: center;
  line-height: 1.5em;
}

.project-image {
  width: 600px;
  height: 100%;
  margin: 0 auto;
  object-fit: cover;
  border: 4px solid #000000;
  box-shadow: 8px 6px #4083ae;
}

.project-card {
  margin: 50px auto;
  right: 55px;
  padding: 30px 30px;
  height: 100%;
  width: 110%;
  max-width: 800px;
  border: 4px solid #000000;
  border-radius: 5px;
  box-shadow: 3px 3px #ec4c4c;
}

.project-card-title {
  font-size: 2.2em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.profile-content-1 {
  padding: 0;
  margin-top: 50px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-content {
  padding: 0;
  margin: 200px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hand-up {
  position: relative;
  top: -350px;
  left: 430px;
}

.mobile-break {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .headshot {
    max-width: 23rem;
  }

  .typed-content {
    min-width: 350px;
    min-height: 200px;
  }

  #greeting .name {
    font-size: 3em;
    margin-top: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .headshot {
    margin-top: 50px;
    max-width: 23rem;
  }

  .nav-links {
    margin: 0;
  }

  .center-text {
    text-align: center;
  }

  .project-card {
    margin: 50px auto 0;
    right: 0;
    bottom: 220px;
    padding: 30px 30px;
    height: 100%;
    width: 92%;
    max-width: 800px;
  }

  .project-image {
    width: 350px;
    height: 100%;
    object-fit: cover;
    box-shadow: 3px 3px #4083ae;
  }

  .profile-content {
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 480px) {
  #greeting .name {
    font-size: 2.2em;
  }

  #greeting .typed-content {
    min-width: 250px;
    font-size: 1.7em;
  }

  .profile-content-1 h1 {
    font-size: 2.3em;
    margin-bottom: 60px;
  }

  .profile-content-1 p {
    font-size: 1.2em;
    max-width: 90%;
    margin: 15px auto;
  }

  .contact-body {
    align-items: flex-start;
  }

  .contact-card {
    margin: 50px auto;
  }

  .hello-container {
    align-items: flex-start;
  }

  .mobile-hidden {
    display: none;
  }

  .hand-container {
    margin-top: 50px;
  }

  .headshot {
    margin-top: 50px;
    max-width: 15rem;
  }

  .mobile-break {
    display: inline-block;
  }
}
